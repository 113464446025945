<template>
  <vca-field :label="$t('email.mailbox.header')">
    <div>{{ select[0].title }}: {{ select[0].subtitle }}</div>
    <br />
    <div v-if="mailboxSelect">
      <p v-html="$t('email.mailbox.select.description')"></p>
      <vca-column>
        <vca-dropdown
          v-model="select"
          :options="mailboxList"
          label=""
          :placeholder="$t('profile.edit.crew.select.placeholder')"
        ></vca-dropdown>
      </vca-column>
    </div>
  </vca-field>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "MailboxSelection",
  computed: {
    select: {
      set(value) {
        this.$store.commit("emails/selectMailbox", value);
        this.getMailbox();
      },
      get() {
        return this.$store.state.emails.selectMailbox.filter((el) => {
          el.title = this.$t(el.title);
          el.subtitle = this.$t(el.subtitle);
          el.label = this.$t(el.label);
          return true;
        });
      },
    },
    mailboxList: {
      get() {
        return this.$store.state.emails.mailboxList.filter((el) => {
          el.title = this.$t(el.title);
          el.subtitle = this.$t(el.subtitle);
          el.label = this.$t(el.label);
          return true;
        });
      },
    },
    ...mapGetters({
      mailboxSelect: "emails/mailboxSelect",
    }),
  },
  methods: {
    getMailbox() {
      this.$store.dispatch({ type: "events/email" });
      this.$store.dispatch({ type: "emails/mailbox/get" });
      this.$store.dispatch({ type: "crews/own" });
    },
  },
};
</script>
