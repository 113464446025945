<template>
  <vca-card>
    <h2>{{ $t("email.list.header") }}</h2>
    <vca-card class="shadowed">
      <MailboxSelection />
    </vca-card>
    <vca-card class="shadowed vca-center">
      <EmailOutboxTable />
      <vca-popup
        v-if="current != null"
        :show="current != null"
        :title="current.subject"
        @close="current = null"
      >
        <EmailPreview
          v-model="current"
          :preview="false"
          :sendable="false"
          :deleteable="false"
        />
      </vca-popup>
    </vca-card>
  </vca-card>
</template>

<script>
import { mapGetters } from "vuex";
import EmailPreview from "@/components/emails/EmailPreview";
import EmailOutboxTable from "@/components/emails/EmailOutboxTable";
import MailboxSelection from "../../components/emails/MailboxSelection.vue";
export default {
  name: "EmailOutboxList",
  components: { EmailOutboxTable, EmailPreview, MailboxSelection },
  created() {
    this.current = null;
  },
  computed: {
    current: {
      get() {
        return this.$store.state.emails.mailbox.message.current;
      },
      set(value) {
        this.$store.commit("emails/mailbox/message/current", value);
      },
    },
    ...mapGetters({
      user: "user/current",
      mailbox: "emails/mailbox/current",
    }),
  },
};
</script>
